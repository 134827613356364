<template>
  <v-container fill-height>
    <v-row justify="center" align="center" v-if="!token">
      <v-col>
        <v-alert color="red lighten-1" type="error" dense> 未授权 / Not Authorized </v-alert>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-else>
      <v-col>
        <v-alert color="#660874" dark>
          {{ currentInfo.name }} 老师，您好，请在下方上传
          {{ currentInfo.target }} 同学的PDF格式的推荐信</v-alert
        >
        <v-alert color="#660874" dark>
          Welcome, Professor {{ currentInfo.name }}! Please upload the recommend letter in PDF
          format for {{ currentInfo.target }} below
        </v-alert>

        <v-card color="#385F73" dark v-if="currentFile">
          <v-card-title class="headline">当前文件</v-card-title>
          <v-list-item>
            <v-list-item-title>文件名/File Name</v-list-item-title>
            <v-list-item-subtitle v-text="currentFile.name"></v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>文件大小/File Size</v-list-item-title>
            <v-list-item-subtitle v-text="currentFile.size + ' bytes'"></v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>上传时间/Upload Time</v-list-item-title>
            <v-list-item-subtitle v-text="currentFile.time"></v-list-item-subtitle>
          </v-list-item>
        </v-card>

        <v-card>
          <v-card-title class="headline" v-text="uploadTitle"></v-card-title>
          <v-card-text>
            <v-file-input
              ref="fileupload"
              @change="selectFile"
              chips
              show-size
              counter
              :disabled="isUploading"
              :accept="uploadTypes"
            ></v-file-input>
          </v-card-text>
          <v-card-actions v-if="isUploading" class="justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-card-actions>
          <v-card-actions v-else class="justify-center">
            <v-btn color="primary" :disabled="!uploadFile" @click="uploadSubmit">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import requests from "@/http";
import consts from "@/consts";

export default {
  data: () => ({
    token: null,
    currentFile: null,
    currentInfo: {
      name: "",
      target: "",
    },
    isUploading: false,
    uploadFile: null,
    uploadTypes: consts.FILE_TYPES.doc,
  }),
  created() {
    this.token = this.$route.query.token;
    this.getCurrentFile();
  },
  computed: {
    uploadTitle() {
      return this.currentFile ? "更新文件/Update File" : "上传文件/Upload File";
    },
  },
  methods: {
    selectFile(file) {
      this.uploadFile = file;
    },
    getCurrentFile() {
      requests
        .get(`/api/camp/letter/upload?token=${this.token}`)
        .then((res) => {
          this.currentInfo = res.data.info;
          if (res.data.file) {
            this.currentFile = res.data.file;
          }
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    uploadSubmit() {
      if (!this.uploadFile) {
        alert("请选择文件/Please select a file");
        return;
      }
      if (this.uploadFile.name.split(".").pop().toLowerCase() !== "pdf") {
        alert("请上传PDF格式的文件/Please upload a PDF file");
        return;
      }
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      formData.append("token", this.token);
      requests
        .post(`/api/camp/letter/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.isUploading = false;
          this.currentFile = res.data.file;
          this.$refs.fileupload.reset();
          alert(res.data.message);
        })
        .catch((err) => {
          this.isUploading = false;
          alert(err.response.data.detail);
        });
    },
  },
};
</script>
